import { __ } from '@comet/i18n'

import AppTypes from '@/types/app'

const { MessageType, AppError } = AppTypes

/**
 * Format errors listed in AppError (usually catched from the URL)
 * @param  {String} key
 * @returns {String}
 */
function formatAppError(key) {
  const trans = {
    [AppError.UNKNOWN_ERROR]: __('ty:app:error:unknown-error'),
    [AppError.INTERNAL_SERVER_ERROR]: __('ty:app:error:internal-error'),
    [AppError.INTERNAL_ERROR]: __('ty:app:error:internal-error'),
    [AppError.FORBIDDEN]: __('ty:app:error:operation-forbidden'),
    [AppError.FAIL_LOGIN]: __('ty:app:error:fail-login'),
    [AppError.FAIL_REGISTER]: __('ty:app:error:fail-register'),
    [AppError.ALREADY_LOGGED_IN]: __('ty:app:error:already-logged-in'),
    [AppError.LOGIN_PROVIDER_DUPLICATED]: __('ty:app:error:login-provider-duplicated'),
    [AppError.INVALID_URL_DOMAIN]: __('ty:app:error:invalid-invalid-url-domain'),
    [AppError.INVALID_EMAIL]: __('ty:app:error:invalid-email'),
    [AppError.EMAIL_ALREADY_USED]: __('ty:app:error:email-already-used'),
    [AppError.EMAIL_ALREADY_USED_WITH_GOOGLE]: __('ty:app:error:email-already-used-with-google'),
    [AppError.EMAIL_ALREADY_USED_WITH_LINKEDIN]: __(
      'ty:app:error:email-already-used-with-linkedin',
    ),
    [AppError.LINKEDIN_URL_INVALID]: __('ty:app:error:linkedin-url-invalid'),
    [AppError.LINKEDIN_ALREADY_USED]: __('ty:app:error:linkedin-already-used'),
    [AppError.USER_ACTIVATION_PENDING]: __('ty:app:error:user-activation-pending'),
    [AppError.INVALID_TOKEN]: __('ty:app:error:invalid-token'),
    [AppError.EXPIRED_TOKEN]: __('ty:app:error:expired-token'),
    [AppError.PHONE_ALREADY_USED]: __('ty:app:error:phone-already-used'),
    [AppError.INVALID_PASSWORD]: __('ty:app:error:invalid-password'),
    [AppError.INVALID_CREDENTIALS]: __('ty:app:error:invalid-credentials'),
    [AppError.USER_NOT_CONFIRMED]: __('ty:app:error:user-not-confirmed'),
    [AppError.MISSION_RATING_PENDING]: __('ty:app:error:mission-rating-pending'),
    [AppError.MISSION_HAS_CHANGED]: __('ty:app:error:mission-has-changed'),
    [AppError.MEETING_NOT_PAST]: __('ty:app:error:meeting-not-past'),
    [AppError.FREELANCE_NOT_DELETABLE]: __('ty:app:error:freelancer-not-deletable'),
    [AppError.INVALID_FILE_TYPE]: __('ty:app:error:invalid-file-type'),
    [AppError.TOO_MANY_SELECTED_MATCHES]: __('ty:app:error:too-many-selected-matches'),
    [AppError.MISSING_SELECTED_MATCHES]: __('ty:app:error:missing-selected-matches'),
    [AppError.PROFILES_SELECTION_LOCKED]: __('ty:app:error:profiles-selection-locked'),
    [AppError.MISSION_MEETING_ALREADY_SELECTED]: __(
      'ty:app:error:mission-meeting-already-selected',
    ),
    [AppError.MISSION_MEETING_UNAVAILABLE]: __('ty:app:error:mission-meeting-unavailable'),
    [AppError.MISSING_MEETING_REFUSAL_REASONS]: __('ty:app:error:missing-meeting-refusal-reasons'),
    [AppError.MISSION_NOT_APPLICABLE]: __('ty:app:error:missing-not-applicable'),
    [AppError.FREELANCE_NOT_SELECTABLE]: __('ty:app:error:freelance-not-selectable'),
    [AppError.CORPORATE_IS_NOT_DELETABLE]: __('ty:app:error:corporate-not-deletable'),
    [AppError.FREELANCER_PARENT_ASSOCIATION_FORBIDDEN]: __(
      'ty:app:error:freelancer-parent-association-forbidden',
    ),
    [AppError.TOO_MANY_LINKEDIN_IMPORTS]: __('ty:app:error:too-many-linkedin-imports'),
  }

  return trans[key] || undefined
}

/**
 * Format Messages
 * @param  {String} key
 * @returns {String}
 */
function formatMessage(key) {
  const trans = {
    [MessageType.SUCCESS]: __('ty:app:message:success'),
    [MessageType.ERROR]: __('ty:app:message:error'),
  }

  return trans[key] || undefined
}

/**
 * Append a pagination total count to the given label
 * @param {String} label
 * @param {Object} pagination
 * @returns {String}
 */
function formatPaginationLabel(label, pagination) {
  return pagination ? `${label} (${pagination.count})` : label
}

export { formatAppError, formatMessage, formatPaginationLabel }
