import { frFR } from '@comet/i18n'

const IS_PRODUCTION = process.env.VUE_APP_ENV === 'production'
const IS_DEVELOPMENT = process.env.VUE_APP_ENV === 'development'

const amplifyDefaultConf = {
  Auth: {
    region: 'eu-west-3',
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    // // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
  },
  cookieStorage: {
    domain: process.env.VUE_APP_COOKIE_DOMAIN_NAME,
    path: '/',
    expire: 365,
    sameSite: 'lax',
    secure: IS_PRODUCTION,
  },
  authenticationFlowType: 'USER_SRP_AUTH',
  oauth: {
    redirectSignIn: `${process.env.VUE_APP_URL}/freelancer`,
    redirectSignOut: process.env.VUE_APP_URL,
    domain: process.env.VUE_APP_OAUTH_REDIRECT_DOMAIN,
    scope: ['email', 'aws.cognito.signin.user.admin', 'openid', 'profile'],
    responseType: 'code',
  },
}

export const generateAmplifyConf = redirectSignIn => {
  return {
    ...amplifyDefaultConf,
    oauth: {
      ...amplifyDefaultConf.oauth,
      redirectSignIn,
    },
  }
}

export const getTeamMemberAmplifyConf = () =>
  generateAmplifyConf(`${process.env.VUE_APP_URL}/backoffice`)
export const getFreelancerAmplifyConf = () =>
  generateAmplifyConf(`${process.env.VUE_APP_URL}/freelancer`)

/**
 * Global configuration of the whole app
 * @type {Object}
 */
export default {
  environment: process.env.VUE_DEPLOYMENT_NAME
    ? process.env.VUE_DEPLOYMENT_NAME
    : process.env.VUE_APP_ENV,
  showHotReloadIndicator: IS_DEVELOPMENT,
  /**
   * Apollo
   */
  apollo: {
    connectToDevTools: !IS_PRODUCTION,
  },
  /**
   * Vue
   */
  vue: {
    devtools: !IS_PRODUCTION,
  },
  /**
   * Vuex
   */
  vuex: {
    strict: !IS_PRODUCTION,
  },
  /**
   * Safety banner (to distinguish envs and prevent to do stupid things
   * in production)
   */
  banner: process.env.VUE_APP_BANNER,
  /**
   * Internationalization configuration (which separate locale and
   * language concepts)
   * The language code is used to identify the right translation file
   * as well as the right Moment's locale. They MUST match.
   */
  i18n: {
    default: 'fr-FR',
    enabled: ['fr-FR'],
    locales: {
      'fr-FR': frFR,
    },
    lang: {
      'fr-FR': 'fr',
    },
    localeStorageKey: 'i18n.locale',
    alertWhenMissing: false,
    silent: true,
  },
  /**
   * Authentication methods available per
   * user type and their associated endpoints
   */
  auth: {
    freelancer: {
      providers: [
        {
          type: 'linkedIn',
          url: '/linkedin',
        },
        {
          type: 'googleFreelance',
          url: '/freelance/google',
        },
      ],
    },
    teamMember: {
      providers: [
        {
          type: 'google',
          url: '/google',
        },
      ],
    },
  },
  /**
   * Logs
   */
  logs: {
    apollo: !IS_PRODUCTION,
    error: !IS_PRODUCTION,
    http: !IS_PRODUCTION,
    i18n: !IS_PRODUCTION,
    storage: false,
    router: !IS_PRODUCTION,
    view: !IS_PRODUCTION,
    searchUi: !IS_PRODUCTION,
  },
  /**
   * URL on which the front is deployed (reachable)
   */
  publicURL: process.env.VUE_APP_URL,
  /**
   * Third-party Services configuration
   * (transmitted entirely to Google Tag Manager)
   */
  services: {
    amplitude: {
      key: process.env.VUE_APP_AMPLITUDE_KEY,
    },
    viralloops: {
      campaignId: process.env.VUE_APP_VIRALLOOPS_CAMPAIGN_ID,
    },
    hubspot: {
      portal: process.env.VUE_APP_HUBSPOT_PORTAL,
      form: process.env.VUE_APP_HUBSPOT_FORM,
    },
    slack: {
      joincomet: process.env.VUE_APP_SLACK_TEAM_ID,
      financeTeamMember: process.env.VUE_APP_SLACK_FINANCE_ID,
    },
    sentry: process.env.VUE_APP_SENTRY_URL,
    typeform: {
      tag: process.env.VUE_APP_ENV,
    },
    maps: {
      apiKey: process.env.VUE_APP_GMAPS_APIKEY,
    },
  },
  /**
   * Common methods
   */
  methods: {
    sendSlackDM: slackId =>
      `https://slack.com/app_redirect?team=${process.env.VUE_APP_SLACK_TEAM_ID}&channel=${slackId}`,
    sendPrivateSlackDM: slackId =>
      `https://slack.com/app_redirect?team=T0SK9FSHZ&channel=${slackId}`,
    hubspotContactLink: hubspotId =>
      `https://app.hubspot.com/contacts/${process.env.VUE_APP_HUBSPOT_PORTAL}/contact/${hubspotId}`,
    checkCompanyOnSociete: companyName =>
      `https://www.societe.com/cgi-bin/search?champs=${encodeURI(companyName)}`,
  },
  /**
   * Storage
   */
  storage: {
    namespace: '@jupiter',
  },
  /**
   * configuration for amplify
   */
  amplify: amplifyDefaultConf,
  /**
   * Whole external endpoints' base-URLs
   */
  urls: {
    api: process.env.VUE_APP_API_URL,
    auth: `${process.env.VUE_APP_API_URL}/auth`,
    graphql: `${process.env.VUE_APP_API_URL}/graphql`,
    clearbit: 'https://autocomplete.clearbit.com/v1',
    website: 'https://comet.co',
    tjmCalculator: 'https://freelance.comet.co/calcul-revenu-freelance-informatique',
    cguClient: 'https://comet.co/conditions-generales-freelances',
    missionsTerms: 'https://www.comet.co/conditions-generales-missions-freelances',
    clientTerms: 'https://www.comet.co/conditions-generales',
    billing: 'https://comet.co/document/MandatDeFacturation.pdf',
    factoring: 'https://comet.co/document/MandatDeGestionAffacturage.pdf',
    calendlyPrepMeeting: 'https://calendly.com/entretien_comet/prepation-entretien-comet',
    joincomet: 'http://joincomet.slack.com',
    joincometLia: `https://slack.com/app_redirect?team=${process.env.VUE_APP_SLACK_TEAM_ID}&channel=${process.env.VUE_APP_SLACK_BOT_ID}`,
    facebook: 'https://www.facebook.com/comet.freelance/',
    twitter: 'https://twitter.com/comet_freelance',
    linkedin: 'https://www.linkedin.com/company/10826715',
    // 05.07.2021, change link medium with old domain name to the actual comet blog
    medium: 'https://www.comet.co/blog',
    shine: 'https://www.shine.fr/partner/comet',
    partners:
      'https://comet.crisp.help/fr/article/comment-commencer-en-tant-quindependant-et-creer-mon-statut-7lnq77/?bust=1660230843887',
    referentSurvey: 'https://hello-comet.typeform.com/to/MofFsr',
    manifesto: 'https://www.comet.co/blog/manifeste-post-freelancing',
    tokenHelp:
      'https://comet.crisp.help/fr/article/en-mission-avec-comet-comment-declarer-le-nombre-de-jours-travailles-chaque-mois-1p71l3o/?1548235431201',
    contractsHelp:
      'https://comet.crisp.help/fr/article/limportance-dun-contrat-de-prestation-146yrz4/?1559830361579',
    linkedInImport:
      'https://comet.crisp.help/fr/article/importer-mon-profil-linkedin-public-lhxl7y/',
    interviewHelp: 'http://bit.ly/2ESatJi',
    urssafHelp:
      'https://comet.crisp.help/fr/article/comment-obtenir-lattestation-de-vigilance-urssaf-16j70iu/',
    rcproHelp:
      'https://comet.crisp.help/fr/article/est-ce-que-jai-besoin-davoir-une-rc-pro-pour-mes-missions-chez-comet-1gmozlb/',
    sireneHelp:
      'https://comet.crisp.help/fr/article/comment-obtenir-un-avis-de-situation-au-repertoire-sirene-7s8bty/',
    gocardlessHelp:
      'https://comet.crisp.help/fr/article/le-prelevement-automatique-h0ywbw/?1565026154111',
    onboardingJoinComet:
      'https://comet.crisp.help/fr/article/rejoindre-comet-un-nouvel-onboarding-vq62k8/?1576057628099',
    onboardingFilledProfile:
      'https://comet.crisp.help/fr/article/pourquoi-est-ce-important-davoir-un-profil-comet-bien-rempli-1jnj8hg/?1576064078619',
    missionsAnswerFaq:
      'https://comet.crisp.help/fr/article/dois-je-repondre-a-toutes-les-propositions-de-mission-1hlsmak/',
    cometPartnerships: 'https://www.comet.co/freelances/partenariats-listing-free',
    signupRequirements:
      'https://comet.crisp.help/fr/article/ton-profil-ne-correspond-pas-aux-criteres-comet-n6ntob/?1574690778062',
    profileCompletion:
      'https://comet.crisp.help/fr/article/remplir-son-profil-pour-debloquer-les-missions-ue7cbn/?1562340062261',
    exLegalModelInvoicingHelp:
      'https://app.hubspot.com/documents/3370273/view/48294262?accessId=e904b6',
    newLegalModelInvoicingHelp:
      'https://app.hubspot.com/documents/3370273/view/48294147?accessId=7bda3e',
    addSkill:
      'https://form.typeform.com/to/qxo6DiIR#userid={userId}&useremail={userEmail}&origin={origin}',
    addJobTitle:
      'https://form.typeform.com/to/TAfY27Zq#userid={userId}&useremail={userEmail}&origin={origin}&submittedat={submittedAt}&jobtitle={jobTitle}',
  },
  /**
   * Emails used throughout the app
   */
  emails: {
    compta: 'compta@comet.co',
    dataPrivacy: 'dataprivacy@comet.co',
  },
  boUrl: process.env.VUE_APP_BO_URL,
}
