<template>
  <NavButton
    :class="classes"
    :icon-on-left="true"
    theme="secondary"
    :external="true"
    @click="onClick"
  >
    <Icon :class="$('icon')" :name="type === LINKEDIN ? 'linkedin-connect' : 'google-connect'" />
    <slot name="label" :class="$('label')" />
  </NavButton>
</template>

<script>
  import { Amplify, Auth } from 'aws-amplify'
  import { getFreelancerAmplifyConf } from '../../../config'

  import _find from 'lodash/find'
  import config from '@/config'

  import I18nMixin from '@/mixins/I18nMixin'
  import RouterMixin from '@/mixins/RouterMixin'

  import NavButton from '@/core/controls/NavButton/NavButton'
  import Icon from '@/core/graphics/Icon/Icon'

  const Type = {
    GOOGLE: 'google',
    GOOGLEFREELANCE: 'googleFreelance',
    LINKEDIN: 'linkedIn',
  }

  const UserType = {
    FREELANCER: 'freelancer',
    TEAMMEMBER: 'teamMember',
  }

  export const AuthProviderButtonType = Type
  export const AuthProviderButtonUserType = UserType

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  NavButton: NavButton.__enums,
  Icon: Icon.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Type,UserType,
    name: 'AuthProviderButton',
    __enums: {
      Type,
      UserType,
    },
    components: {
      NavButton,
      Icon,
    },
    mixins: [I18nMixin, RouterMixin],
    props: {
      /**
       * What kind of authentication are we trying to perform
       * Should be an instance of GraphQL type ProviderTypeEnum
       * can be:
       *  - google
       *  - googleFreelance
       *  - linkedIn
       */
      type: {
        type: String,
        required: true,
      },
      /**
       * What kind of user should we try to authenticate
       * can be:
       *  - freelancer
       *  - teamMember
       */
      userType: {
        type: String,
        required: true,
      },
      /**
       * If you need to link an account (google/linkedin)
       * to the connected user, set linkToUser to true
       */
      linkToUser: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    constants: {
      LINKEDIN: Type.LINKEDIN,
    },
    computed: {
      /**
       * Dynamically apply css classes according to type
       * @type {Array.<String>}
       */
      classes() {
        const { type } = this

        return ['auth-provider-button', `auth-provider-button--${type}`]
      },
      /**
       * Computes the authentication url according to:
       * - Environement-specific base url for auth
       * - [userType] providers url configurated in app config
       * - Redirect link passed in props
       * @type {String}
       */
      to() {
        const urlParams = new URLSearchParams(window.location.search)
        const signupTokenParam = urlParams.get('signupToken')

        const { type, userType } = this
        const { providers } = config.auth[userType]
        const provider = _find(providers, p => p.type === type)
        const base = config.urls.auth
        const linkToUser = this.linkToUser ? `?linkToUser=${this.linkToUser}` : ''
        const signupToken = signupTokenParam ? `?signupToken=${signupTokenParam}` : ''

        return provider ? `${base}${provider.url}${linkToUser}${signupToken}` : null
      },
    },
    created() {
      const urlParams = new URLSearchParams(window.location.search)
      const autoLoginOauthProvider = urlParams.get('autoLoginOauthProvider')
      if (autoLoginOauthProvider === this.type) {
        this.onClick()
      }
    },
    methods: {
      /**
       * Called when clicking the button
       */
      async onClick() {
        const urlParams = new URLSearchParams(window.location.search)
        const signupTokenParam = urlParams.get('signupToken')

        const customState = new URLSearchParams()

        customState.append('aws', 'true')
        customState.append('provider', this.type)

        if (this.$route.query?.to) {
          customState.append('to', this.$route.query.to)
        }

        Amplify.configure(getFreelancerAmplifyConf())
        if (signupTokenParam) {
          customState.append('signupToken', signupTokenParam)
        } else if (this.linkToUser) {
          try {
            const currentUser = await Auth.currentAuthenticatedUser()
            customState.append('email', currentUser.attributes.email)
          } catch {
            customState.append('sub', 'no-sub')
          }
          customState.append('linkToUser', this.linkToUser)
          customState.append('to', '/freelancer/parameters')
        }

        // if (this.type === Type.LINKEDIN) {
        //   customState.append('connection', 'Linkedin-Connection-dev')
        // }

        await Auth.federatedSignIn({
          provider: this.type === Type.GOOGLEFREELANCE ? 'Google' : 'Auth0-Linkedin',
          customState: `${customState.toString()}`,
        })

        return null
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .auth-provider-button {
    display: flex
    align-items: center
    justify-content: center

    &-icon {
      font-size: 20px
      position: relative
      margin-right: 8px
      background-repeat: no-repeat
      background-size: contain
      background-position: center
    }

    &--linkedIn {
      >>> button {
        background: var(--color-linkedin)
        color: var(--color-font-contrast)

        &:not(:disabled):hover {
          background: var(--color-linkedin-hover)
        }
      }
    }
  }
</style>
