<script>
  import { int } from '@/utils/transform'
  import config from '@/config'

  /**
   * Router log activation / deactivation
   * @type {Boolean}
   */
  const LOGS_ENABLED = config.logs.router

  export default {
    computed: {
      /**
       * Expose the route object to the extended component
       * @type {Object}
       */
      route() {
        return this.$route
      },
      /**
       * Expose the first error of the route
       * @returns {Number}
       */
      routeError() {
        const { error } = this.route.query

        if (error) {
          return int(Array.isArray(error) ? error[0] : error)
        }

        return null
      },
    },
    methods: {
      /**
       * Used to execute a push action on the router
       * @returns {Promise}
       */
      navigate(location, replace = false) {
        return new Promise((resolve, reject) => {
          const { $router } = this

          if (!$router) {
            console.warn('RouterMixin Error : No router found on the component instance.')
            reject()
            return
          }

          if (LOGS_ENABLED) {
            console.info('[Router] navigate : ', location)
          }

          // Handle 'back' location to retreive the
          // previous view
          if (location === 'back') {
            $router.back()
            resolve()
            return
          }

          // If location is a string and starts with `https://domain`
          // we must remove it given that "vue-router" doesn't work
          // with absolute urls.
          if (typeof location === 'string') {
            location = location.split(window.location.host).pop()
          }

          // Call the '$router.replace' action if the 'replace'
          // param is specified
          const action = replace ? $router.replace : $router.push

          action.call(
            $router,
            location,
            () => resolve(location),
            () => {},
          )
        })
      },
    },
  }
</script>
