import { __ } from '@comet/i18n'
import moment from 'moment-timezone'
import MatchTypes from '@/types/match'

function shortenLastName(fullName) {
  const nameParts = fullName.trim().split(' ')
  if (nameParts.length < 2) {
    return fullName
  }
  const firstName = nameParts[0]
  const lastNameWords = nameParts.slice(1)
  const lastNameInitials = lastNameWords.map(word => word[0].toUpperCase()).join('')
  return `${firstName} ${lastNameInitials}`
}

/**
 * Return the formatted Freelancer Agent Chip
 * This function needs to import matchFreelancerAgentChipFragment.gql
 */
function formatMatchFreelancerAgentChip(match) {
  // Qualified by, less than 2 months, CORPORATE_VALIDATION only
  if (
    match &&
    (match.status === MatchTypes.MatchStatus.CORPORATE_VALIDATION ||
      match.status === MatchTypes.MatchStatus.CORPORATE_CONFIRMED) &&
    match.faQualifiedAt &&
    match.faQualifiedBy &&
    match.presentedAt &&
    moment(match.presentedAt).isBefore(moment(match.faQualifiedAt).add(2, 'months'))
  ) {
    return {
      text: __('ty:match:pipeline-item:freelancer-agent:qualified-in-hubspot-by', {
        fa: shortenLastName(match.faQualifiedBy.user.fullName),
      }),
      tooltip: __('ty:match:pipeline-item:freelancer-agent:qualified-in-hubspot-by:tooltip', {
        fa: match.faQualifiedBy.user.fullName,
      }),
    }
  }
  // Pushed by
  else if (match && match.faAppliedBy) {
    return {
      text: __('ty:match:pipeline-item:freelancer-agent:pushed-by', {
        fa: shortenLastName(match.faAppliedBy.user.fullName),
      }),
      tooltip: __('ty:match:pipeline-item:freelancer-agent:pushed-by:tooltip', {
        fa: match.faAppliedBy.user.fullName,
      }),
    }
  }
  // Proposed by
  else if (match && match.faProposedBy) {
    return {
      text: __('ty:match:pipeline-item:freelancer-agent:proposed-by', {
        fa: shortenLastName(match.faProposedBy.user.fullName),
      }),
      tooltip: __('ty:match:pipeline-item:freelancer-agent:proposed-by:tooltip', {
        fa: match.faProposedBy.user.fullName,
      }),
    }
  }
  // Selected by
  else if (match.faSelectedBy) {
    return {
      text: __('ty:match:pipeline-item:freelancer-agent:selected-by', {
        fa: shortenLastName(match.faSelectedBy.user.fullName),
      }),
      tooltip: __('ty:match:pipeline-item:freelancer-agent:selected-by:tooltip', {
        fa: match.faSelectedBy.user.fullName,
      }),
    }
  }
  // Qualified by, less than 2 months
  else if (
    match &&
    match.faQualifiedAt &&
    match.faQualifiedBy &&
    moment(match.createdAt).isBefore(moment(match.faQualifiedAt).add(2, 'months'))
  ) {
    return {
      text: __('ty:match:pipeline-item:freelancer-agent:qualified-in-hubspot-by', {
        fa: shortenLastName(match.faQualifiedBy.user.fullName),
      }),
      tooltip: __('ty:match:pipeline-item:freelancer-agent:qualified-in-hubspot-by:tooltip', {
        fa: match.faQualifiedBy.user.fullName,
      }),
    }
  }
  // Managed By
  else if (match.freelancerAgent) {
    return {
      text: __('ty:match:pipeline-item:freelancer-agent:managed-by', {
        fa: shortenLastName(match.freelancerAgent.user.fullName),
      }),
      tooltip: __('ty:match:pipeline-item:freelancer-agent:managed-by:tooltip', {
        fa: match.freelancerAgent.user.fullName,
      }),
    }
  }
}

export { formatMatchFreelancerAgentChip }
