import _difference from 'lodash/difference'
import _reduce from 'lodash/reduce'

import { __ } from '@comet/i18n'

import MatchTypes from '@/types/match'
import UserTypes from '@/types/user'

const {
  MatchCancelSource,
  MatchCorporateRefusalReason,
  MatchFreelancerRefusal,
  MatchTeamMemberRefusalReason,
  MatchFreelancerStatus,
  MatchStatus,
  PreMatchingSource,
  PostMeetingFreelancerReason,
} = MatchTypes

const { UserRole } = UserTypes

/**
 * Returns the formatted "short" label corresponding to the given mission match's status
 * @param {String} status
 * @return {String}
 */
function formatMatchStatus(status) {
  const trans = {
    [MatchStatus.TEAM_MEMBER_VALIDATION]: __('ty:match:status:team-member-validation'),
    [MatchStatus.TEAM_MEMBER_VALIDATION_CANCELLED]: __(
      'ty:match:status:team-member-validation-cancelled',
    ),
    [MatchStatus.TEAM_MEMBER_VALIDATION_DECLINED]: __(
      'ty:match:status:team-member-validation-declined',
    ),
    [MatchStatus.FREELANCE_APPLICATION]: __('ty:match:status:freelance-application'),
    [MatchStatus.FREELANCE_PROPOSAL]: __('ty:match:status:freelance-proposal'),
    [MatchStatus.FREELANCE_PROPOSAL_CANCELLED]: __('ty:match:status:freelance-proposal-cancelled'),
    [MatchStatus.FREELANCE_DECLINED]: __('ty:match:status:freelance-declined'),
    [MatchStatus.FREELANCE_NOT_INTERESTED]: __('ty:match:status:freelance-not-interested'),
    [MatchStatus.TEAM_MEMBER_PRESELECTION]: __('ty:match:status:team-member-preselection'),
    [MatchStatus.FREELANCE_APPLICATION_CANCELLED]: __(
      'ty:match:status:freelance-application-cancelled',
    ),
    [MatchStatus.CORPORATE_VALIDATION]: __('ty:match:status:corporate-validation'),
    [MatchStatus.CORPORATE_DECLINED]: __('ty:match:status:corporate-declined'),
    [MatchStatus.CORPORATE_DUPLICATED]: __('ty:match:status:corporate-duplicated'),
    [MatchStatus.CORPORATE_CONFIRMED]: __('ty:match:status:corporate-confirmed'),
    [MatchStatus.TEAM_MEMBER_CANCELLED]: __('ty:match:status:team-member-cancelled'),
  }

  return trans[status] || undefined
}

/**
 * Format freelancer refusal reason for a match
 * @param   {String}
 * @returns {String}
 */
function formatMatchFreelancerRefusal(key) {
  const trans = {
    [MatchFreelancerRefusal.TJM]: __('ty:mission:freelancer-mission-refusal-reason:tjm'),
    [MatchFreelancerRefusal.LOCATION]: __('ty:mission:freelancer-mission-refusal-reason:location'),
    [MatchFreelancerRefusal.STACK]: __('ty:mission:freelancer-mission-refusal-reason:stack'),
    [MatchFreelancerRefusal.START_DATE]: __(
      'ty:mission:freelancer-mission-refusal-reason:start-date',
    ),
    [MatchFreelancerRefusal.RHYTHM]: __('ty:mission:freelancer-mission-refusal-reason:rhythm'),
    [MatchFreelancerRefusal.DURATION]: __('ty:mission:freelancer-mission-refusal-reason:duration'),
    [MatchFreelancerRefusal.COMPANY]: __('ty:mission:freelancer-mission-refusal-reason:company'),
    [MatchFreelancerRefusal.IS_ESN]: __('ty:mission:freelancer-mission-refusal-reason:is-esn'),
    [MatchFreelancerRefusal.OTHER]: __('ty:mission:freelancer-mission-refusal-reason:other'),
    // New reasons
    [MatchFreelancerRefusal.CANDIDATE_FREELANCE_OTHER_MISSION_FOUND]: __(
      'ty:mission:match-refusal-reason:new:candidate-freelance-other-mission-found',
    ),
    [MatchFreelancerRefusal.PROCESS_FREELANCE_OTHER_MISSION_FOUND]: __(
      'ty:mission:match-refusal-reason:new:process-freelance-other-mission-found',
    ),
    [MatchFreelancerRefusal.PROCESS_FREELANCE_INADEQUAT_MISSION]: __(
      'ty:mission:match-refusal-reason:new:process-freelance-inadequat-mission',
    ),
    [MatchFreelancerRefusal.INTERVIEW_FREELANCE_SOFT_SKILLS_WARNING]: __(
      'ty:mission:match-refusal-reason:new:interview-freelance-soft-skills-warning',
    ),
    [MatchFreelancerRefusal.INTERVIEW_FREELANCE_OTHER_MISSION_FOUND]: __(
      'ty:mission:match-refusal-reason:new:interview-freelance-other-mission-found',
    ),
    [MatchFreelancerRefusal.INTERVIEW_FREELANCE_INADEQUAT_MISSION]: __(
      'ty:mission:match-refusal-reason:new:interview-freelance-inadequat-mission',
    ),
    [MatchFreelancerRefusal.CONFIRMED_FREELANCE_CANT_AGREE_ON_CONTRACT_TERMS]: __(
      'ty:mission:match-refusal-reason:new:confirmed-freelance-cant-agree-on-contract-terms',
    ),
    [MatchFreelancerRefusal.CONFIRMED_FREELANCE_OTHER_MISSION_FOUND]: __(
      'ty:mission:match-refusal-reason:new:confirmed-freelance-other-mission-found',
    ),
    [MatchFreelancerRefusal.PROPOSAL_FREELANCE_UNAVAILABLE]: __(
      'ty:mission:match-refusal-reason:new:proposal-freelance-unavailable',
    ),
    [MatchFreelancerRefusal.PROPOSAL_FREELANCE_DAILY_RATE_TOO_LOW]: __(
      'ty:mission:match-refusal-reason:new:proposal-freelance-daily-rate-too-low',
    ),
    [MatchFreelancerRefusal.PROPOSAL_FREELANCE_INADAPTED_OFFER]: __(
      'ty:mission:match-refusal-reason:new:proposal-freelance-inadapted-offer',
    ),
    [MatchFreelancerRefusal.PROPOSAL_FREELANCE_INADAPTED_LOCATION]: __(
      'ty:mission:match-refusal-reason:new:proposal-freelance-inadapted-location',
    ),
    [MatchFreelancerRefusal.PROPOSAL_FREELANCE_WONT_WORK_FOR_THIS_COMPANY]: __(
      'ty:mission:match-refusal-reason:new:proposal-freelance-wont-work-for-this-company',
    ),
    [MatchFreelancerRefusal.PROPOSAL_FREELANCE_INADAPTED_RYTHM]: __(
      'ty:mission:match-refusal-reason:new:proposal-freelance-inadapted-rythm',
    ),
    [MatchFreelancerRefusal.PROPOSAL_FREELANCE_NOT_ENOUGH_REMOTE]: __(
      'ty:mission:match-refusal-reason:new:proposal-freelance-not-enough-remote',
    ),
    [MatchFreelancerRefusal.CANDIDATE_FREELANCE_NO_MORE_INTERESTED]: __(
      'ty:mission:match-refusal-reason:new:candidate-freelance-no-more-interested',
    ),
    [MatchFreelancerRefusal.PROCESS_FREELANCE_NO_MORE_INTERESTED]: __(
      'ty:mission:match-refusal-reason:new:process-freelance-no-more-interested',
    ),
    [MatchFreelancerRefusal.INTERVIEW_FREELANCE_NO_MORE_INTERESTED]: __(
      'ty:mission:match-refusal-reason:new:interview-freelance-no-more-interested',
    ),
  }

  return trans[key] || undefined
}

/**
 * Format corporate refusal reason for a match
 * @param   {String}
 * @returns {Object}
 */
function formatMatchCorporateRefusalReason(key) {
  const trans = {
    [MatchCorporateRefusalReason.RETRIBUTION]: {
      text: __('ty:mission:corporate-match-refusal-reason:retribution'),
    },
    [MatchCorporateRefusalReason.SENIORITY]: {
      text: __('ty:mission:corporate-match-refusal-reason:seniority'),
      sub: __('ty:mission:corporate-match-refusal-reason:seniority-sub'),
    },
    [MatchCorporateRefusalReason.PASTWORK]: {
      text: __('ty:mission:corporate-match-refusal-reason:pastWork'),
    },
    [MatchCorporateRefusalReason.AVAILABILITY]: {
      text: __('ty:mission:corporate-match-refusal-reason:availability'),
    },
    [MatchCorporateRefusalReason.SKILLS]: {
      text: __('ty:mission:corporate-match-refusal-reason:skills'),
    },
    [MatchCorporateRefusalReason.LOCATION]: {
      text: __('ty:mission:corporate-match-refusal-reason:location'),
      sub: __('ty:mission:corporate-match-refusal-reason:location-sub'),
    },
    [MatchCorporateRefusalReason.INCOMPLETEPROFILE]: {
      text: __('ty:mission:corporate-match-refusal-reason:incompleteProfile'),
    },
    [MatchCorporateRefusalReason.SOCIALSKILLS]: {
      text: __('ty:mission:corporate-match-refusal-reason:socialSkills'),
    },
    [MatchCorporateRefusalReason.MOTIVATION]: {
      text: __('ty:mission:corporate-match-refusal-reason:motivation'),
      sub: __('ty:mission:corporate-match-refusal-reason:motivation-sub'),
    },
    [MatchCorporateRefusalReason.NO_SHOW]: {
      text: __('ty:mission:corporate-match-refusal-reason:noShow'),
    },
    [MatchCorporateRefusalReason.OTHER]: {
      text: __('ty:mission:corporate-match-refusal-reason:other'),
    },
    // New reasons
    [MatchCorporateRefusalReason.PROCESS_CORPO_EXCESSIVE_PRICE]: {
      text: __('ty:mission:match-refusal-reason:new:process-corpo-excessive-price'),
    },
    [MatchCorporateRefusalReason.PROCESS_CORPO_LOCATION]: {
      text: __('ty:mission:match-refusal-reason:new:process-corpo-location'),
    },
    [MatchCorporateRefusalReason.PROCESS_CORPO_MOBILITY_ISSUE]: {
      text: __('ty:mission:match-refusal-reason:new:process-corpo-mobility-issue'),
    },
    [MatchCorporateRefusalReason.PROCESS_CORPO_MISSING_KEY_SKILL]: {
      text: __('ty:mission:match-refusal-reason:new:process-corpo-missing-key-skill'),
    },
    [MatchCorporateRefusalReason.PROCESS_CORPO_ALREADY_KNOWN_SOLUTION]: {
      text: __('ty:mission:match-refusal-reason:new:process-corpo-already-known-solution'),
    },
    [MatchCorporateRefusalReason.INTERVIEW_CORPO_EXCESSIVE_PRICE]: {
      text: __('ty:mission:match-refusal-reason:new:interview-corpo-excessive-price'),
    },
    [MatchCorporateRefusalReason.INTERVIEW_CORPO_LOCATION]: {
      text: __('ty:mission:match-refusal-reason:new:interview-corpo-location'),
    },
    [MatchCorporateRefusalReason.INTERVIEW_CORPO_MOBILITY_ISSUE]: {
      text: __('ty:mission:match-refusal-reason:new:interview-corpo-mobility-issue'),
    },
    [MatchCorporateRefusalReason.INTERVIEW_CORPO_MISSING_KEY_SKILL]: {
      text: __('ty:mission:match-refusal-reason:new:interview-corpo-missing-key-skill'),
    },
    [MatchCorporateRefusalReason.INTERVIEW_CORPO_ALREADY_KNOWN_SOLUTION]: {
      text: __('ty:mission:match-refusal-reason:new:interview-corpo-already-known-solution'),
    },
    [MatchCorporateRefusalReason.INTERVIEW_CORPO_SOFT_SKILLS_WARNING]: {
      text: __('ty:mission:match-refusal-reason:new:interview-corpo-soft-skills-warning'),
    },
    [MatchCorporateRefusalReason.PROCESS_CORPO_LACK_OF_SENIORITY]: {
      text: __('ty:mission:match-refusal-reason:new:process-corpo-lack-of-seniority'),
    },
    [MatchCorporateRefusalReason.INTERVIEW_CORPO_LACK_OF_SENIORITY]: {
      text: __('ty:mission:match-refusal-reason:new:interview-corpo-lack-of-seniority'),
    },
  }

  return trans[key] || {}
}

function formatMatchCorporateRefusalReasons(keys, role, status) {
  const base = {
    [MatchStatus.CORPORATE_DECLINED]: __('ty:mission:corporate-match-refusal-reasons:fre:base'),
  }
  const other = {
    [MatchStatus.CORPORATE_DECLINED]: __('ty:mission:corporate-match-refusal-reasons:fre:other'),
  }

  if (role === UserRole.FREELANCER) {
    const freTrans = {
      [MatchCorporateRefusalReason.RETRIBUTION]: {
        [MatchStatus.CORPORATE_DECLINED]: __(
          'ty:mission:corporate-match-refusal-reasons:fre:retribution',
        ),
      },
      [MatchCorporateRefusalReason.SENIORITY]: {
        [MatchStatus.CORPORATE_DECLINED]: __(
          'ty:mission:corporate-match-refusal-reasons:fre:seniority',
        ),
      },
      [MatchCorporateRefusalReason.PASTWORK]: {
        [MatchStatus.CORPORATE_DECLINED]: __(
          'ty:mission:corporate-match-refusal-reasons:fre:pastWork',
        ),
      },
      [MatchCorporateRefusalReason.AVAILABILITY]: {
        [MatchStatus.CORPORATE_DECLINED]: __(
          'ty:mission:corporate-match-refusal-reasons:fre:availability',
        ),
      },
      [MatchCorporateRefusalReason.SKILLS]: {
        [MatchStatus.CORPORATE_DECLINED]: __(
          'ty:mission:corporate-match-refusal-reasons:fre:skills',
        ),
      },
      [MatchCorporateRefusalReason.SOCIALSKILLS]: {
        [MatchStatus.CORPORATE_DECLINED]: __(
          'ty:mission:corporate-match-refusal-reasons:fre:socialSkills',
        ),
      },
      [MatchCorporateRefusalReason.NO_SHOW]: {
        [MatchStatus.CORPORATE_DECLINED]: __(
          'ty:mission:corporate-match-refusal-reasons:fre:noShow',
        ),
      },
      [MatchCorporateRefusalReason.LOCATION]: {
        [MatchStatus.CORPORATE_DECLINED]: __(
          'ty:mission:corporate-match-refusal-reasons:fre:location',
        ),
      },
      [MatchCorporateRefusalReason.INCOMPLETEPROFILE]: {
        [MatchStatus.CORPORATE_DECLINED]: __(
          'ty:mission:corporate-match-refusal-reasons:fre:incompleteProfile',
        ),
      },
    }

    const hiddenReasons = [MatchCorporateRefusalReason.MOTIVATION]

    const trimed = _difference(keys, [MatchCorporateRefusalReason.OTHER, ...hiddenReasons])

    if (keys.length === 1) {
      if (hiddenReasons.includes(keys[0]) || keys[0] === MatchCorporateRefusalReason.OTHER) {
        return other[status]
      }

      return `${base[status]} ${freTrans[keys[0]][status]}.`
    }

    return _reduce(
      trimed,
      (acc, key, index) => {
        if (index === 0) {
          return `${base[status]} ${freTrans[key][status]}`
        }

        return index < trimed.length - 1
          ? `${acc}, ${freTrans[key][status]}`
          : `${acc}, ${freTrans[key][status]}.`
      },
      '',
    )
  }

  return null
}

/**
 * Format corporate refusal reason for a match
 * @param   {String}
 * @returns {Object}
 */
function formatMatchTeamMemberRefusalReason(key) {
  const trans = {
    [MatchTeamMemberRefusalReason.RETRIBUTION]: {
      text: __('ty:mission:team-member-match-refusal-reason:retribution'),
    },
    [MatchTeamMemberRefusalReason.SENIORITY]: {
      text: __('ty:mission:team-member-match-refusal-reason:seniority'),
      sub: __('ty:mission:team-member-match-refusal-reason:seniority-sub'),
    },
    [MatchTeamMemberRefusalReason.PASTWORK]: {
      text: __('ty:mission:team-member-match-refusal-reason:pastWork'),
    },
    [MatchTeamMemberRefusalReason.AVAILABILITY]: {
      text: __('ty:mission:team-member-match-refusal-reason:availability'),
    },
    [MatchTeamMemberRefusalReason.SKILLS]: {
      text: __('ty:mission:team-member-match-refusal-reason:skills'),
    },
    [MatchTeamMemberRefusalReason.LOCATION]: {
      text: __('ty:mission:team-member-match-refusal-reason:location'),
      sub: __('ty:mission:team-member-match-refusal-reason:location-sub'),
    },
    [MatchTeamMemberRefusalReason.INCOMPLETEPROFILE]: {
      text: __('ty:mission:team-member-match-refusal-reason:incompleteProfile'),
    },
    [MatchTeamMemberRefusalReason.SOCIALSKILLS]: {
      text: __('ty:mission:team-member-match-refusal-reason:socialSkills'),
    },
    [MatchTeamMemberRefusalReason.MOTIVATION]: {
      text: __('ty:mission:team-member-match-refusal-reason:motivation'),
      sub: __('ty:mission:team-member-match-refusal-reason:motivation-sub'),
    },
    [MatchTeamMemberRefusalReason.NO_SHOW]: {
      text: __('ty:mission:team-member-match-refusal-reason:noShow'),
    },
    [MatchTeamMemberRefusalReason.OTHER_MISSION]: {
      text: __('ty:mission:team-member-match-refusal-reason:otherMission'),
    },
    [MatchTeamMemberRefusalReason.OTHER]: {
      text: __('ty:mission:team-member-match-refusal-reason:other'),
    },
    // New reasons
    [MatchTeamMemberRefusalReason.CANDIDATE_COMET_EXCESSIVE_PRICE]: {
      text: __('ty:mission:match-refusal-reason:new:candidate-comet-excessive-price'),
    },
    [MatchTeamMemberRefusalReason.CANDIDATE_COMET_LACK_OF_SENIORITY]: {
      text: __('ty:mission:match-refusal-reason:new:candidate-comet-lack-of-seniority'),
    },
    [MatchTeamMemberRefusalReason.CANDIDATE_COMET_MISSING_KEY_SKILL]: {
      text: __('ty:mission:match-refusal-reason:new:candidate-comet-missing-key-skill'),
    },
    [MatchTeamMemberRefusalReason.CANDIDATE_COMET_LOCATION_ISSUE]: {
      text: __('ty:mission:match-refusal-reason:new:candidate-comet-location-issue'),
    },
    [MatchTeamMemberRefusalReason.CANDIDATE_COMET_MOBILITY_ISSUE]: {
      text: __('ty:mission:match-refusal-reason:new:candidate-comet-mobility-issue'),
    },
    [MatchTeamMemberRefusalReason.CANDIDATE_COMET_CANT_START_EARLY_ENOUGH]: {
      text: __('ty:mission:match-refusal-reason:new:candidate-comet-cant-start-early-enough'),
    },
    [MatchTeamMemberRefusalReason.CANDIDATE_COMET_SOFT_SKILLS_WARNING]: {
      text: __('ty:mission:match-refusal-reason:new:candidate-comet-soft-skills-warning'),
    },
    [MatchTeamMemberRefusalReason.CONFIRMED_COMET_IMPOSSIBLE_TO_CONTRACT]: {
      text: __('ty:mission:match-refusal-reason:new:confirmed-comet-impossible-to-contract'),
    },
    [MatchTeamMemberRefusalReason.CONFIRMED_COMET_CANT_AGREE_ON_CONTRACT_TERMS]: {
      text: __('ty:mission:match-refusal-reason:new:confirmed-comet-cant-agree-on-contract-terms'),
    },
    [MatchTeamMemberRefusalReason.PROPOSAL_COMET_MISSION_OFFER_CANCELED]: {
      text: __('ty:mission:match-refusal-reason:new:proposal-comet-mission-offer-canceled'),
    },
  }

  return trans[key] || {}
}

/**
 * Format the source of the freelancer refusal (TM / CORPO / FREE)
 * @param   {String}
 * @returns {String}
 */
function formatMatchRefusalSource(key) {
  const trans = {
    [MatchCancelSource.FREELANCE]: __('ty:mission:match-refusal-reason:not-interested'),
    [MatchCancelSource.CORPORATE]: __('ty:mission:match-refusal-reason:refused-by-corporate'),
    [MatchCancelSource.TEAM_MEMBER]: __('ty:mission:match-refusal-reason:cancelled-by-team-member'),
  }

  return trans[key] || undefined
}

/**
 * Format match label
 * @param  {String} match freelancer status
 * @returns {String}
 */
function formatMatchStatusLabel(key) {
  const trans = {
    [MatchFreelancerStatus.FAILED]: __('ty:match:status:label:failed'),
    [MatchFreelancerStatus.NEW]: __('ty:match:status:label:matching'),
    [MatchFreelancerStatus.CANDIDAT]: __('ty:match:status:label:candidate'),
    [MatchFreelancerStatus.PRESENTATION]: __('ty:match:status:label:presentation'),
    [MatchFreelancerStatus.AVAILABILITY]: __('ty:match:status:label:availabilty'),
    [MatchFreelancerStatus.DATE_PICKING]: __('ty:match:status:label:date-picking'),
    [MatchFreelancerStatus.MEETING_SET]: __('ty:match:status:label:meeting-set'),
    [MatchFreelancerStatus.MEETING_DONE]: __('ty:match:status:label:meeting-past'),
    [MatchFreelancerStatus.REFUSED]: __('ty:match:status:label:refused'),
    [MatchFreelancerStatus.READY]: __('ty:match:status:label:ready'),
    [MatchFreelancerStatus.ON_GOING]: __('ty:match:status:label:ongoing'),
    [MatchFreelancerStatus.FINISHED]: __('ty:match:status:label:finished'),
    [MatchFreelancerStatus.DEAL_CREATED]: __('ty:match:status:label:deal-created'),
    [MatchFreelancerStatus.FREE_ESTIMATE]: __('ty:match:status:label:free-terms'),
    [MatchFreelancerStatus.CONTRACT_CREATION]: __('ty:match:action:label:contract-creation'),
    [MatchFreelancerStatus.SIGNING]: __('ty:match:status:label:signing'),
    [MatchFreelancerStatus.REFUSED_BY_FREELANCE]: __('ty:match:status:label:freelancer-refused'),
  }

  return trans[key] || undefined
}

/**
 * Format match action wording
 * @param  {String} match freelancer status
 * @returns {String}
 */
function formatMatchActionWording(key, params) {
  const trans = {
    [MatchFreelancerStatus.FAILED]: __('ty:match:action:wording:failed'),
    [MatchFreelancerStatus.CANDIDAT]: __('ty:match:action:wording:candidate'),
    [MatchFreelancerStatus.AVAILABILITY]: __('ty:match:action:wording:availabilty'),
    [MatchFreelancerStatus.DATE_PICKING]: __('ty:match:action:wording:date-picking'),
    [MatchFreelancerStatus.MEETING_SET]: __('ty:match:action:wording:meeting-set', params),
    [MatchFreelancerStatus.MEETING_DONE]: __('ty:match:action:wording:meeting-past'),
    [MatchFreelancerStatus.REFUSED]: __('ty:match:action:wording:refused'),
    [MatchFreelancerStatus.READY]: __('ty:match:action:wording:ready', params),
    [MatchFreelancerStatus.ON_GOING]: __('ty:match:action:wording:ongoing'),
    [MatchFreelancerStatus.FINISHED]: __('ty:match:action:wording:finished'),
    [MatchFreelancerStatus.DEAL_CREATED]: __('ty:match:action:wording:deal-created'),
    [MatchFreelancerStatus.FREE_ESTIMATE]: __('ty:match:action:wording:free-terms'),
    [MatchFreelancerStatus.CONTRACT_CREATION]: __('ty:match:action:wording:contract-creation'),
    [MatchFreelancerStatus.SIGNING]: __('ty:match:action:wording:signing'),
    [MatchFreelancerStatus.REFUSED_BY_FREELANCE]: __('ty:match:action:wording:freelancer-refused'),
  }

  return trans[key] || undefined
}

function formatPostMeetingFreelancerRefusalReason(key) {
  const trans = {
    [PostMeetingFreelancerReason.RETRIBUTION]: {
      text: __('ty:match:post-meeting-freelancer-refusal:retribution'),
    },
    [PostMeetingFreelancerReason.LOCATION]: {
      text: __('ty:match:post-meeting-freelancer-refusal:location'),
      sub: __('ty:match:post-meeting-freelancer-refusal:location:sub'),
    },
    [PostMeetingFreelancerReason.TECHNICAL_CONTENT]: {
      text: __('ty:match:post-meeting-freelancer-refusal:technical-content'),
      sub: __('ty:match:post-meeting-freelancer-refusal:technical-content:sub'),
    },
    [PostMeetingFreelancerReason.CONDITIONS]: {
      text: __('ty:match:post-meeting-freelancer-refusal:conditions'),
      sub: __('ty:match:post-meeting-freelancer-refusal:conditions:sub'),
    },
    [PostMeetingFreelancerReason.OTHER_MISSION]: {
      text: __('ty:match:post-meeting-freelancer-refusal:other-mission'),
      sub: __('ty:match:post-meeting-freelancer-refusal:other-mission:sub'),
    },
    [PostMeetingFreelancerReason.OTHER]: {
      text: __('ty:match:post-meeting-freelancer-refusal:other'),
    },
  }

  return trans[key] || undefined
}

/**
 * Get color of the freelancer match status
 * @param  {String}
 * @returns {String}
 */
function getMatchColor(status) {
  switch (status) {
    case MatchFreelancerStatus.READY:
    case MatchFreelancerStatus.ON_GOING:
    case MatchFreelancerStatus.FINISHED:
      return 'green'
    case MatchFreelancerStatus.REFUSED_BY_FREELANCE:
    case MatchFreelancerStatus.REFUSED:
    case MatchFreelancerStatus.FAILED:
      return 'red'
    default:
      return 'blue'
  }
}

/**
 * Return the formatted pre-matching source
 */
function formatPreMatchingSource(key) {
  const trans = {
    [PreMatchingSource.MATCHING_ENGINE]: __('ty:match:pre-matching-source:matching-engine'),
    [PreMatchingSource.MATCHING_V2]: __('ty:match:pre-matching-source:matching-v2'),
    [PreMatchingSource.PERFECT_MATCH]: __('ty:match:pre-matching-source:perfect-match'),
    [PreMatchingSource.EXTENDED_MATCH]: __('ty:match:pre-matching-source:extended-match'),
    [PreMatchingSource.TEAM_MEMBER_ACTION]: __('ty:match:pre-matching-source:team-member-action'),
    [PreMatchingSource.TELESCOPE]: __('ty:match:pre-matching-source:telescope'),
    [PreMatchingSource.FREELANCER_ACTION]: __('ty:match:pre-matching-source:freelancer-action'),
    [PreMatchingSource.EXTERNAL_ACQUISITION]: __(
      'ty:match:pre-matching-source:external-acquisition',
    ),
    [PreMatchingSource.MATCHING_V4_0]: __('ty:match:pre-matching-source:matching-v4-0'),
    [PreMatchingSource.MATCHING_V4_1]: __('ty:match:pre-matching-source:matching-v4-1'),
    [PreMatchingSource.FREELANCER_AGENT]: __('ty:match:pre-matching-source:freelancer-agent'),

    [PreMatchingSource.FREELANCERS_INDEX]: __('ty:match:pre-matching-source:freelancers-index'),
  }

  return trans[key] || undefined
}

export {
  formatMatchStatus,
  formatMatchActionWording,
  formatMatchCorporateRefusalReason,
  formatMatchCorporateRefusalReasons,
  formatMatchTeamMemberRefusalReason,
  formatMatchFreelancerRefusal,
  formatMatchRefusalSource,
  formatMatchStatusLabel,
  formatPreMatchingSource,
  formatPostMeetingFreelancerRefusalReason,
  getMatchColor,
}
